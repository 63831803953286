import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [
    "playButtonHolder",
    "loadingIndicatorHolder"
  ]

  initialize(){}

  connect(){
    this.videoId = this.data.get('videoId')
    this.vdoPlayerContainerId = this.data.get('vdoPlayerContainerId')
    this.vdoCipherPlaybackInfoUrl = `/api/vdo-cipher-video/${this.videoId}/playback-info.json`
    this.video = null
  }

  disconnect(){}

  play(e){
    e.preventDefault()
    this.playButtonHolderTarget.classList.add('hidden')
    this.loadingIndicatorHolderTarget.classList.remove('hidden')
    $(`#${this.vdoPlayerContainerId}`).show();
    $.get(this.vdoCipherPlaybackInfoUrl, function(playbackInfo) {
      this._addVideo(playbackInfo);
      this._setEventListener();
    }.bind(this));

  }

  _addVideo(playbackInfo) {
    var videos, video;

    vdo.add({
      otp: playbackInfo["otp"],
      playbackInfo: playbackInfo["playbackInfo"],
      plugins: [
        {
          name: 'keyboard',
          options: {
            preset: 'default',
            bindings: {
              'Space': (function(_this) {
                return function(player) {
                  if (player.status === 1) {
                    return player.pause();
                  } else {
                    return player.play();
                  }
                };
              })(this),
              'k': (function(_this) {
                return function(player) {
                  if (player.status === 1) {
                    return player.pause();
                  } else {
                    return player.play();
                  }
                };
              })(this),
              'f': (function(_this) {
                return function(player) {
                  return player.fullscreen(!player.isFullscreen);
                };
              })(this),
              'Up': (function(_this) {
                return function(player) {
                  return player.setVolume(player.volume + 0.2);
                };
              })(this),
              'Down': (function(_this) {
                return function(player) {
                  return player.setVolume(player.volume - 0.2);
                };
              })(this),
              'm': (function(_this) {
                return function(player) {
                  if (player.muted === true) {
                    return player.unmute();
                  } else {
                    return player.mute();
                  }
                };
              })(this),
              'Left': (function(_this) {
                return function(player) {
                  return player.seek(player.currentTime - 10);
                };
              })(this),
              'Right': (function(_this) {
                return function(player) {
                  return player.seek(player.currentTime + 10);
                };
              })(this),
              'j': (function(_this) {
                return function(player) {
                  return player.seek(player.currentTime - 20);
                };
              })(this),
              'l': (function(_this) {
                return function(player) {
                  return player.seek(player.currentTime + 20);
                };
              })(this),
              '}': (function(_this) {
                return function(player) {
                  return player.playbackRate += 0.2;
                };
              })(this),
              '{': (function(_this) {
                return function(player) {
                  return player.playbackRate -= 0.2;
                };
              })(this),
              '>': (function(_this) {
                return function(player) {
                  return player.playbackRate += 0.2;
                };
              })(this),
              '<': (function(_this) {
                return function(player) {
                  return player.playbackRate -= 0.2;
                };
              })(this)
            }
          }
        }
      ],
      theme: '9ae8bbe8dd964ddc9bdb932cca1cb59a',
      autoplay: true,
      container: document.querySelector(`#${this.vdoPlayerContainerId}`)
    });

    var videos = vdo.getObjects();

    // Set this.video
    for (const x in videos) {
      video = videos[x];

      if (video._playbackInfo.videoId == this.videoId){
        this.video = video;
      }
    }
  }

  _setEventListener() {
    this.video.addEventListener('play', function() {
      this._focusVideo();
    }.bind(this));

    this.video.addEventListener('load', function() {
      this.loadingIndicatorHolderTarget.classList.add('hidden')
    }.bind(this));
  }

  _focusVideo(){
    var videos, video;
    var videos = vdo.getObjects();
    for (const x in videos) {
      video = videos[x];

      console.log(video._playbackInfo.videoId)
      if (video._playbackInfo.videoId == this.videoId){
        video.focus();
      } else {
        video.pause();
      }
    }
  }
}
