import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
import { EVENTS } from 'shared/constants'
import PubSub from 'pubsub-js'
import { unsubscribeTokens } from 'shared/utils/utils'


export default class extends Controller {
  static targets = [ "form", "status" ]

  initialize(){
    this.subscriptions = []
  }

  connect() {
    this.save = this.save.bind(this)
    this.timeout  = null
    this.duration = this.data.get("duration") || 1000
    this._attachEventlisteners()

    this.subscriptions.push(PubSub.subscribe(EVENTS.AUTOSAVE.REATTACH, this._attachEventlisteners.bind(this)))
  }

  disconnect(){
    unsubscribeTokens(this.subscriptions)
  }

  save() {
    console.log("save called")
    clearTimeout(this.timeout)

    this.timeout = setTimeout(function () {
      PubSub.publish(EVENTS.AUTOSAVE.SAVING)
      this._setStatus("Saving...")
      Rails.fire(this.formTarget, 'submit')
    }.bind(this), this.duration)
  }

  success() {
    PubSub.publish(EVENTS.AUTOSAVE.SAVED)
    this._setStatus("Saved!")
  }

  error() {
    PubSub.publish(EVENTS.AUTOSAVE.ERROR)
    this._setStatus("Unable to save!")
  }

  _attachEventlisteners(){
    $(this.element).find('.color-picker').keyup(() => this.save())
    $(this.element).find('.color-picker').spectrum({
      showInput: true,
      showAlpha: false,
      move: () => this.save(),
      hide: (e, color) => {
        if($(e.element).get() !== color){
          this.save()
        }
      }
    })

    $(this.element).find('input[type=text]').on('input', () => this.save())
    $(this.element).find('input[type=hidden]').change(() => this.save()) // For hidden inputs, need to trigger 'change' event manually like `$(hiddenInput).val(newValue).trigger('change')`
    $(this.element).find('textarea').on('input', () => this.save())
    $(this.element).find('trix-editor').bind('trix-change', () => this.save())
    $(this.element).find('select').change(() => this.save())
  }

  _setStatus(message) {
    if(this.hasStatusTarget){
      this.statusTarget.textContent = message
      this.timeout = setTimeout(() => {
        this.statusTarget.textContent = ""
      }, 2000)
    }
  }
}
