import { Controller } from 'stimulus'

export default class extends Controller {
  //[TODO] Error handling while something goes wrong while liking the post
  static targets = [
    "currentUserPostedReviewRating",
    "currentUserPostedReviewText",
    "reviewRatingFormField",  // This is hidden field
    "reviewTextFormField",
    "addOrEditButtons",
    "addReviewButton",
    "reviewForm",
    "currentUserReview"
  ]

  _setStarRating() {
    // there is no reviewFormTarget when user is not logged in
    if(!this.hasReviewFormTarget){
      return;
    }

    let rateYoTarget = $("#userStarRating")

    if(this.hasCurrentUserPostedReviewRatingTarget){
      this.reviewRatingFormFieldTarget.value = this.currentUserPostedReviewRatingTarget.value;
    }

    rateYoTarget.rateYo({
      fullStar: true,
      rating: this.reviewRatingFormFieldTarget.value
    });

    rateYoTarget.rateYo("option", "rating", this.reviewRatingFormFieldTarget.value);

    rateYoTarget.rateYo().on("rateyo.change", function (e, data) {
      this.reviewRatingFormFieldTarget.value = data.rating;
    }.bind(this));
  }

  addReview(event){
    let [data, status, xhr] = event.detail;
    this.currentUserReviewTarget.innerHTML = xhr.response;
    this.reviewFormTarget.reset();
    this.hideReviewForm(event);
    this._hideAddReviewButton();
  }

  showReviewForm(event){
    event.preventDefault();
    this._setStarRating();
    if(this.hasCurrentUserPostedReviewTextTarget){
      this.reviewTextFormFieldTarget.value = this.currentUserPostedReviewTextTarget.innerHTML;
    }
    this.addOrEditButtonsTarget.classList.add('hidden');
    this.reviewFormTarget.classList.remove('hidden');
  }

  hideReviewForm(event){
    event.preventDefault();
    this.addOrEditButtonsTarget.classList.remove('hidden');
    this.reviewFormTarget.classList.add('hidden');
  }

  _hideAddReviewButton(){
    if(this.hasAddReviewButtonTarget){
      this.addReviewButtonTarget.classList.add('hidden')
    }
  }
}
