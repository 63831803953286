import { Controller } from "stimulus"

export default class extends Controller {
  //[TODO] Error handling while something goes wrong while liking the post
  static targets = [
    "replies",
    "repliesList",
    "repliesListContent",
    "viewRepliesListLink",
    "newReplyForm"
  ]

  connect(){ }

  addReply(event){
    let [data, status, xhr] = event.detail;
    this.repliesListContentTarget.innerHTML += xhr.response;
    this.newReplyFormTarget.reset()
  }

  showReplies(event) {
    event.preventDefault();
    this.repliesTarget.classList.remove('hidden');
    this.showRepliesList(event);
  }

  showRepliesList(event){
    event.preventDefault();
    this.repliesListTarget.classList.remove('hidden');
    this.hideViewRepliesListLink(event);
  }

  hideViewRepliesListLink(event){
    event.preventDefault();
    this.viewRepliesListLinkTarget.classList.add('hidden');
  }
}
