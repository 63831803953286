import { PubSub } from 'pubsub-js';
import 'slick-carousel';

export function unsubscribeTokens(tokens) {
  tokens.forEach((tokens) => {
    PubSub.unsubscribe(tokens);
  })
}

export function initCarousel(elementIdentifier, mode, parent = document) {
  if(mode == "single") {
    $(parent).find(elementIdentifier).slick({
      autoplay: true,
      autoplaySpeed: 4000,
      infinite: true,
      speed: 300,
      arrows: true,
      slidesToShow: 1,
      centerMode: true,
      prevArrow: "<div class='slick-prev p-2 bg-white shadow border'><svg class='h-5 w-5' fill='currentColor' viewBox='0 0 20 20'><path fill-rule='evenodd' d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z' clip-rule='evenodd'></path></svg></div>",
      nextArrow: "<div class='slick-next p-2 bg-white shadow border'><svg class='h-5 w-5' fill='currentColor' viewBox='0 0 20 20'><path fill-rule='evenodd' d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z' clip-rule='evenodd'></path></svg></div>"
    });
  }
  else if (mode == "multiple-centered") {
    $(parent).find(elementIdentifier).slick({
      autoplay: true,
      autoplaySpeed: 4000,
      centerMode: true,
      centerPadding: '300px',
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      prevArrow: "<div class='slick-prev p-2 bg-white shadow border'><svg class='h-5 w-5' fill='currentColor' viewBox='0 0 20 20'><path fill-rule='evenodd' d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z' clip-rule='evenodd'></path></svg></div>",
      nextArrow: "<div class='slick-next p-2 bg-white shadow border'><svg class='h-5 w-5' fill='currentColor' viewBox='0 0 20 20'><path fill-rule='evenodd' d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z' clip-rule='evenodd'></path></svg></div>",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerPadding: '300px'
          }
        },
        {
          breakpoint: 950,
          settings: {
            centerPadding: '200px'
          }
        },
        {
          breakpoint: 900,
          settings: {
            centerPadding: '150px'
          }
        },
        {
          breakpoint: 800,
          settings: {
            centerPadding: '100px'
          }
        },
        {
          breakpoint: 600,
          settings: {
            centerPadding: '60px'
          }
        },
        {
          breakpoint: 480,
          settings: {
            centerPadding: '60px'
          }
        }
      ]
    });
  }
  else {
    $(parent).find(elementIdentifier).slick({
      infinite: false,
      speed: 300,
      slidesToShow: 4,
      slidesToScroll: 3,
      variableWidth: true,
      arrows: true,
      prevArrow: "<div class='slick-prev p-2 bg-white shadow border'><svg class='h-5 w-5' fill='currentColor' viewBox='0 0 20 20'><path fill-rule='evenodd' d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z' clip-rule='evenodd'></path></svg></div>",
      nextArrow: "<div class='slick-next p-2 bg-white shadow border'><svg class='h-5 w-5' fill='currentColor' viewBox='0 0 20 20'><path fill-rule='evenodd' d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z' clip-rule='evenodd'></path></svg></div>",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  }
}

export function initAllCarousels(parent = document){
  initCarousel(".banners-carousel", "multiple-centered", parent);
  initCarousel(".product-row__items-carousel", "multiple", parent)
  initCarousel(".product-row__items-carousel--alternate-size", "multiple", parent)
  initCarousel(".product-row__live-sessions-carousel", "multiple", parent)
  $(parent).find('.testimonials-carousel').slick({
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    slidesToShow: 1,
    prevArrow: "<div class='slick-prev p-2 bg-white shadow border'><svg class='h-5 w-5' fill='currentColor' viewBox='0 0 20 20'><path fill-rule='evenodd' d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z' clip-rule='evenodd'></path></svg></div>",
    nextArrow: "<div class='slick-next p-2 bg-white shadow border'><svg class='h-5 w-5' fill='currentColor' viewBox='0 0 20 20'><path fill-rule='evenodd' d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z' clip-rule='evenodd'></path></svg></div>",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });
}

export function removeAllCarousels(){
  $('.banners-carousel').slick('unslick');
  $('.product-row__items-carousel').slick('unslick');
  $('.product-row__items-carousel--alternate-size').slick('unslick');
  $('.product-row__live-sessions-carousel').slick('unslick');
  $('.testimonials-carousel').slick('unslick');
}
