import { Controller } from 'stimulus'
import 'libs/jquery.fitvids.js'
import { initAllCarousels } from 'shared/utils/utils'

export default class extends Controller {
  static targets = ["placeholder", "loadingIndicator"]

  connect() {
    this.appUid = this.data.get('appUid')
    this.uiPageId = this.data.get('uiPageId');
    this.previewMode = this.data.get('previewMode')
    this.showDummyContent = this.data.get('showDummyContent')


    this._load_sections()
    this._hideLoadingIndicator()
  }

  _load_sections() {
    if(this.placeholderTargets.length > 0){
      this._load_section(this.placeholderTarget.dataset.uiPageSectionId)
    }
  }

  _load_section(pageSectionId) {
    $.ajax({
      url: `/api/design/ui/page_sections/${pageSectionId}?&preview_mode=${this.previewMode}&show_dummy_content=${this.showDummyContent}`,
      method: "GET",
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data) {
        $(`#${data.resourceDomId}`).replaceWith(eval(data.template))
        $(`#${data.resourceDomId}`).find('img').on('error', function() { $(this).attr('src', "https://dummyimage.com/16:9x720/a2b5c4/a2b5c4.jpg"); });
        $(`#${data.resourceDomId}`).find(".video-container").fitVids()
        initAllCarousels(`#${data.resourceDomId}`)

        if(this.placeholderTargets.length > 0){
          this._load_section(this.placeholderTarget.dataset.uiPageSectionId)
        } else {
          // all sections are loaded
          this._hideLoadingIndicator()
        }
      }.bind(this),
      error: function(jqXHR, status, error) {
        console.log(error);
      }.bind(this)
    });
  }

  _hideLoadingIndicator() {
    if(this.placeholderTargets.length == 0) {
      this.loadingIndicatorTarget.style.display = "none"
    }
  }
}
