import { Controller } from 'stimulus'
import { EVENTS } from 'shared/constants'
import PubSub from 'pubsub-js'

export default class extends Controller {
  static targets = ["image"]

  initialize(){
    this.addFile()
    this.addLogoImage()
  }

  connect(){}

  disconnect(){}

  changeImage(event){
    console.log("change image")
    console.log(this.data.get('sharedImageId'))
    let imageId = this.data.get('sharedImageId')
    let imageCode = this.data.get('sharedImageCode')
    let url = this.data.get('pageSectionUpdateUrl')
    let pageSectionId = this.data.get('pageSectionId')
    this._change_image(url, imageId, pageSectionId, imageCode)
  }

  addFile(){
    $('#fileupload-' + this.data.get('sharedImageCode')).fileupload({
      dataType: 'json',
      add: function (e, form_data) {
        console.log("add image to upload")
        console.log( form_data.files[0]["id"])
        form_data.form[0]["Content-Type"].value = form_data.files[0].type
        form_data.form[0]["key"].value = "uploads/shared_image/" + form_data.form[0]["code"].value
        + "/" + form_data.files[0]["name"]
        form_data.submit()
      },
      progressall: function (e, data) {
        let progress = parseInt(data.loaded / data.total * 100, 10);
        if (progress == 100)
        {
          $('#progress').css(
            {"display": "none"}
          )
        } else {
        $('#progress').css(
          {"width": progress + '%', "display": "block", "background-color": "#0D84FF"}
        )
        }
      },
      done: function (e, data) {
        console.log("file upload done")
        console.log(this.data.get('sharedImageId'))
        console.log(this.data.get('sharedImageCode'))
        let imageCode = this.data.get('sharedImageCode')
        let imageId = this.data.get('sharedImageId')
        let imageName = data.files[0]["name"]
        let url = this.data.get('pageSectionUpdateUrl')
        let pageSectionId = this.data.get('pageSectionId')
        console.log(this.data.get('pageSectionUpdateUrl'))
        this._image_uploaded(url, imageId, imageName, pageSectionId, imageCode)
      }.bind(this)
    })
  }

  _image_uploaded(url, imageId, imageName, pageSectionId, imageCode){
    $.ajax({
      url: url + "/shared-image/" + imageId + "/image-uploaded",
      method: "PUT",
      data:{image_name: imageName},
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data){
        let response = eval(data)
        $("#uploadForm-"+ imageCode).css({"display": "none"})
        $("#showImage-"+ imageCode).css({"display": "block"})
        $("#showImage-"+ imageCode).html(response["form"])
        PubSub.publish(EVENTS.THEME.PAGE_SECTION.IMAGE_UPLOAD, {"response": response, "preview": evalResponse["preview"], "pageSectionId": pageSectionId})
      }.bind(this)
    })
  }

  _change_image(url, imageId, pageSectionId, imageCode){
    $.ajax({
      url: url + "/shared-image/" + imageId + "/change-image",
      method: "PUT",
      data:{},
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data){
        let response = eval(data)
        $("#uploadForm-"+ imageCode).css({"display": "block"})
        $("#showImage-"+ imageCode).css({"display": "none"})
        $("#uploadForm-"+ imageCode).html(response["form"])
        PubSub.publish(EVENTS.THEME.PAGE_SECTION.CHANGE_IMAGE, {"response": response, "preview": evalResponse["preview"], "pageSectionId": pageSectionId})
        console.log("send form here")
      }.bind(this)
    })
  }

  addLogoImage(){
    $('#headerlogoupload').fileupload({
      dataType: 'json',
      add: function (e, form_data) {
        console.log("upload header logo image")
        form_data.form[0]["Content-Type"].value = form_data.files[0].type
        form_data.form[0]["key"].value = "uploads/shared_image/" + form_data.form[0]["code"].value
        + "/" + form_data.files[0]["name"]
        form_data.submit()
      },
      progressall: function (e, data) {
        let progress = parseInt(data.loaded / data.total * 100, 10);
        if (progress == 100)
        {
          $('#progress').css(
            {"display": "none"}
          )
        } else {
        $('#progress').css(
          {"width": progress + '%', "display": "block", "background-color": "#0D84FF"}
        )
        }
      },
      done: function (e, data) {
        console.log("logo image upload done")
        console.log(this.data.get('sharedImageId'))
        let imageId = this.data.get('sharedImageId')
        let imageName = data.files[0]["name"]
        let url = this.data.get('logoImageUploadUrl')
        let themeLayoutId = this.data.get('themeLayoutId')
        console.log(this.data.get('logoImageUploadUrl'))
        this._header_logo_image_upload(url, imageName, themeLayoutId)
      }.bind(this)
    })
  }

  _header_logo_image_upload(url, imageName, themeLayoutId){
    $.ajax({
      url: url ,
      method: "PUT",
      data:{image_name: imageName},
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data){
        let response = eval(data)
        PubSub.publish(EVENTS.THEME.LAYOUT.UPLOAD_LOGO_IMAGE, {"response": response, "preview": response["preview"], "themeLayoutId": themeLayoutId})
        $("#uploadLogoImageForm").css({"display": "none"})
        $("#showLogoImage").css({"display": "block"})
        $("#showLogoImage").html(response["form"])
      }.bind(this)
    })
  }

  changeLogoImage(){
    let imageName = null
    let url = this.data.get('logoImageUploadUrl')
    let themeLayoutId = this.data.get('themeLayoutId')
    console.log(this.data.get('logoImageUploadUrl'))
    this._header_logo_image_upload(url, imageName, themeLayoutId)
  }

}
