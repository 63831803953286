import { Controller } from 'stimulus'
import { EVENTS } from 'shared/constants'
import PubSub from 'pubsub-js'

export default class extends Controller {

  initialize(){}

  connect(){}

  disconnect(){}

  playVideo(event){
    console.log("play video")
    console.log(this.data.get('vdoCipherVideoId'))
    let vdoCipherVideoId = this.data.get('vdoCipherVideoId')
    this._get_playback_info(vdoCipherVideoId)
  }

  _get_playback_info(vdoCipherVideoId){
    $.ajax({
      url: "/api/private-video/" + vdoCipherVideoId + "/playback-info",
      method: "GET",
      data:{},
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data){
        var vdoCipherPlayer = new VdoPlayer({
          otp: data.otp,
          playbackInfo: btoa(JSON.stringify({
            videoId: vdoCipherVideoId
          })),
          theme: '9ae8bbe8dd964ddc9bdb932cca1cb59a',
          container: document.querySelector('#embedBox')
        });
        vdoCipherPlayer.addEventListener('load', function() {
          vdoCipherPlayer.play()
        })
      }.bind(this)
    })
  }

  startVideo(vdoCipherPlayer){
    console.log("start video")
    vdoCipherPlayer.play()
  }

}
