import { Controller } from 'stimulus'
import { EVENTS } from 'shared/constants'
import PubSub from 'pubsub-js'

export default class extends Controller {
  static targets = [ ]

  initialize(){
  }

  connect() {
    this.fetch()
  }

  disconnect(){
  }

  fetch() {
    fetch(this.data.get("url"))
    .then((response) => response.text())
    .then((html) => {
      this.element.innerHTML = html;
      $(this.element).find('img').on('error', function() { $(this).attr('src', "https://dummyimage.com/16:9x720/a2b5c4/a2b5c4.jpg"); });
      this.loadScripts();
      PubSub.publish(EVENTS.CONTENT.LOADED)
    });
  }

  loadScripts() {
    this.element.querySelectorAll('script').forEach((content) => {
      const script = document.createElement('script');
      script.innerHTML = content.innerHTML;
      document.head.appendChild(script).parentNode.removeChild(script);
    });

  }

}
