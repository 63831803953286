import { Controller } from 'stimulus'
import { EVENTS } from 'shared/constants'
import PubSub from 'pubsub-js'
import { unsubscribeTokens } from 'shared/utils/utils'


export default class extends Controller {
  static targets = ["dependents"]

  initialize(){

  }

  connect() {
    this._select(this.data.get('selected'))
  }

  disconnect(){
  }

  change(e){
    e.preventDefault()
    this._select(e.target.value)
  }

  _select(value){
    let dependents = Array.from(this.dependentsTarget.children)
    dependents.forEach(function(ele){
      if(ele.dataset.showFor == value){
        ele.classList.remove('hidden')
      }
      else{
        ele.classList.add('hidden')
      }
    })
    this.data.set('selected', value)
  }
}
