import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'loggedInStatus',
    'loggedOutStatus',
    'logoutButton',
    'loggingOutButton',
    'itemRow'
  ]

  // #region Getters

  get loginId() {
    return this.data.get("loginId");
  }

  get isOnTooManyLoginsScreen() {
    return this.data.get("isOnTooManyLoginsScreen");
  }

  get tooManyLoginsController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("too-many-logins-containter"), "too-many-logins--index")
  }

  // #endregion Getters

  logout(event) {
    event.preventDefault()
    this.logoutButtonTarget.classList.add('hidden')
    this.loggingOutButtonTarget.classList.remove('hidden')

    Rails.ajax({
      type: `DELETE`,
      url: `/api/web/auth/logins/${this.loginId}/logout`,
      success: function(data) {
        this.loggedInStatusTarget.classList.add('hidden')
        this.loggedOutStatusTarget.classList.remove('hidden')
        this.loggingOutButtonTarget.classList.add('hidden')

        if (this.isOnTooManyLoginsScreen == "yes") {
          this.itemRowTarget.classList.add('hidden')
          if (data['max_logins_limit_reached'] == false) {
            this.tooManyLoginsController.enableContinueButton()
          }
        }
      }.bind(this),
      error: function(_data) {
        alert("Failed to logout from that device")
        this.logoutButtonTarget.classList.remove('hidden')
        this.loggingOutButtonTarget.classList.add('hidden')
      }.bind(this)
    })

  } // logout
}
