//REFERENCE: https://atech.blog/atech/creating-a-simple-custom-event-system-in-javascript
//we are not using anything from the above link its only to learn how to pass events and listen to them
//This is how we make our components/controllers talk to each other
//https://addyosmani.com/resources/essentialjsdesignpatterns/book/#observerpatternjavascript

// Don't use event names which starts with a same string because PubSub unsubscribes all the descendant topics if you unsubscribe a topic
// For example; PubSub.unsubscribe('a'); will unsubsribe all the below events
// PubSub.unsubscribe('a.b');
// PubSub.unsubscribe('a.b.c');
// PubSub.unsubscribe('ab');
export const EVENTS = {
  "PAGE_MAKER_MODAL":{
    "SHOW": "PAGE_MAKER.MODAL.SHOW",
    "HIDE": "PAGE_MAKER.MODAL.HIDE"
  },
  "THEME":{
    "GLOBAL_SECTION": {
      "EDIT_FOOTER_LINK_GROUP": "THEME.GLOBAL_SECTION.FOOTER_LINK_GROUP_EDIT",
      "UPDATE_FOOTER_LINK_GROUP": "THEME.GLOBAL_SECTION.FOOTER_LINK_GROUP_UPDATE"
    },
    "LAYOUT":{
      "EDIT_HEADER" : "THEME.LAYOUT.EDIT.HEADER",
      "UPDATE_HEADER" : "THEME.LAYOUT.UPDATE.HEADER",
      "ADD_HEADER_LINK": "THEME.LAYOUT.HEADER.ADD_LINK",
      "DELETE_HEADER_LINK": "THEME.LAYOUT.HEADER.DELETE_LINK",
      "UPLOAD_LOGO_IMAGE": "THEME.LAYOUT.HEADER.UPLOAD_LOGO_IMAGE",
      "EDIT_FOOTER" : "THEME.LAYOUT.EDIT.FOOTER",
      "UPDATE_FOOTER" : "THEME.LAYOUT.UPDATE.FOOTER",
      "ADD_FOOTER_LINK": "THEME.LAYOUT.FOOTER.ADD_LINK",
      "DELETE_FOOTER_LINK": "THEME.LAYOUT.FOOTER.DELETE_LINK"
    },
    "PAGE":{
      "PUBLISH" : "THEME.PAGE.PUBLISH",
      "NEEDS_PUBLISHING" : "THEME.PAGE.NEEDS_PUBLISHING",
      "PUBLISHED" : "THEME.PAGE.PUBLISHED"
    },
    "PAGE_SECTION":{
      "ADD" : "THEME.PAGE_SECTION.ADD",
      "ADD_SECTION_PREVIEW" : "THEME.PAGE_SECTION.APPEND_PREVIEW",
      "REMOVE": "THEME.PAGE_SECTION.REMOVE",
      "REMOVE_SECTION_PREVIEW": "THEME.PAGE_SECTION.DELETE_PREVIEW",
      "RESTORE_SECTION_PREVIEW": "THEME.PAGE_SECTION.RESTORE_PREVIEW",
      "EDIT": "THEME.PAGE_SECTION.EDIT",
      "UPDATE": "THEME.PAGE_SECTION.UPDATE",
      "SHOW_LIST": "THEME.PAGE_SECTION.SHOW_LIST",
      "RELOAD": "THEME.PAGE_SECTION.RELOAD",
      "UPDATE_TEXT": "THEME.PAGE_SECTION.TEXT.UPDATE",
      "UPDATE_IMAGE_WITH_TEXT": "THEME.PAGE_SECTION.IMAGE_WITH_TEXT.UPDATE",
      "IMAGE_UPLOAD": "THEME.PAGE_SECTION.IMAGE_WITH_TEXT.IMAGE_UPLOAD",
      "CHANGE_IMAGE": "THEME.PAGE_SECTION.IMAGE_WITH_TEXT.CHANGE_UPLOAD",
      "UPDATE_VIDEO_WITH_TEXT": "THEME.PAGE_SECTION.VIDEO_WITH_TEXT.UPDATE",
      "VIDEO_UPLOADED": "THEME.PAGE_SECTION.VIDEO_WITH_TEXT.VIDEO_UPLOADED",
      "VIDEO_CHANGED": "THEME.PAGE_SECTION.VIDEO_WITH_TEXT.VIDEO_CHANGED",
      "VIDEO_DELETED": "THEME.PAGE_SECTION.VIDEO_DELETED",
      "ADD_FAQ": "THEME.PAGE_SECTION.FAQ.ADD",
      "DELETE_FAQ": "THEME.PAGE_SECTION.FAQ.DELETE",
      "UPDATE_FAQ": "THEME.PAGE_SECTION.FAQ.UPDATE",
      "ADD_TESTIMONIAL": "THEME.PAGE_SECTION.TESTIMONIAL.ADD",
      "DELETE_TESTIMONIAL": "THEME.PAGE_SECTION.TESTIMONIAL.DELETE",
      "UPDATE_TESTIMONIAL": "THEME.PAGE_SECTION.TESTIMONIAL.UPDATE",
      "ADD_INSTRUCTORS_BIO": "THEME.PAGE_SECTION.INSTRUCTORS_BIO.ADD",
      "DELETE_INSTRUCTORS_BIO": "THEME.PAGE_SECTION.INSTRUCTORS_BIO.DELETE",
      "UPDATE_INSTRUCTORS_BIO": "THEME.PAGE_SECTION.INSTRUCTORS_BIO.UPDATE",
      "UPDATE_ALL_PRODUCTS": "THEME.PAGE_SECTION.ALL_PRODUCTS.UPDATE"
    }
  },
  "AUTOSAVE": {
    "SAVING" : "AUTOSAVE.SAVING",
    "SAVED" : "AUTOSAVE.SAVED",
    "ERROR" : "AUTOSAVE.ERROR",
    "REATTACH" : "AUTOSAVE.REATTACH"
  },
  "ACCORDION": {
    "ACTIVETED": "ACCORDION.ACTIVETED"
  },
  "TAB":{
    "ACTIVATED": "TAB.ACTIVATED"
  },
  "CONTENT": {
    'LOADED': "CONTENT.LOADED"
  }
}
