import { Controller } from "stimulus"

export default class extends Controller {
  //[TODO] Error handling while something goes wrong while liking the post
  static targets = [
    "likeCountWrapper",
    "likeCount",
    "likePostForm",
    "comments",
    "commentsList",
    "newCommentForm"
  ]
  connect() {}

  like(event){
    let [data, status, xhr] = event.detail;
    if(xhr.response == 0){
      this._hideLikeCount()
    }
    else {
      this.likeCountTarget.innerHTML = xhr.response;
      this._showLikeCount()
    }
  }

  addComment(event){
    let [data, status, xhr] = event.detail;
    this.commentsListTarget.insertAdjacentHTML("afterbegin", xhr.response);
    this.newCommentFormTarget.reset()
  }

  toggleComments(event){
    event.preventDefault();
    this.commentsTarget.classList.toggle('hidden');
  }

  _hideLikeCount(){
    this.likeCountWrapperTarget.classList.add('hidden');
  }
  _showLikeCount(){
    this.likeCountWrapperTarget.classList.remove('hidden');
  }
}
