import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "searchFormField",
    "searchTextField",
    "clearButton",
    "loader"
  ]
  connect() {}

  clearButtonClicked(e){
    this.toggle_loading()
    this.searchTextFieldTarget.value = "";
    $("#search").val('')
    this.searchFormFieldTarget.submit()
  }
  toggle_loading() {
    this.clearButtonTarget.classList.add("hidden");
    this.loaderTarget.classList.add("flex")
    this.loaderTarget.classList.remove("hidden")

  }

  toggle_view() {
  }
}
