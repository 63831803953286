
import 'slick-carousel/slick/slick.css';
import 'rateyo/src/jquery.rateyo.css';
import "applications/client_site/client_site.css";
import "components/page_widgets/jumbotron/css/_jumbotron.css";
import "components/page_widgets/banners/css/_banners.css";
import "components/page_widgets/testimonials/css/_testimonials.css";
import "components/page_widgets/expanded_categories/css/_expanded_categories.css";

import "applications/client_site/client_site.js"
