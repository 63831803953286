import { Controller } from 'stimulus'

export default class extends Controller {

  initialize(){ console.log("Video Initialized!") }

  connect(){ console.log("Video Connected!") }

  disconnect(){}

  play(e){
    e.preventDefault()
    let videoId = this.data.get('id')
    let productId = this.data.get('productId')
    let productType = this.data.get('productType')
    $("#promoVideoVdoPlayerContainer").show();
    return $.get("/api/promo-video/" + videoId + "/playback-info.json", function(playbackInfo) {
      this._addVideo(playbackInfo);
      this._setProductPromoVideoEventListener(productId, productType);
    }.bind(this));
  }

  _addVideo(playbackInfo) {
    var video, videos;
    videos = vdo.getObjects();
    if (videos.length === 0) {
      vdo.add({
        otp: playbackInfo["otp"],
        playbackInfo: playbackInfo["playbackInfo"],
        plugins: [
          {
            name: 'keyboard',
            options: {
              preset: 'default',
              bindings: {
                'Space': (function(_this) {
                  return function(player) {
                    if (player.status === 1) {
                      return player.pause();
                    } else {
                      return player.play();
                    }
                  };
                })(this),
                'k': (function(_this) {
                  return function(player) {
                    if (player.status === 1) {
                      return player.pause();
                    } else {
                      return player.play();
                    }
                  };
                })(this),
                'f': (function(_this) {
                  return function(player) {
                    return player.fullscreen(!player.isFullscreen);
                  };
                })(this),
                'Up': (function(_this) {
                  return function(player) {
                    return player.setVolume(player.volume + 0.2);
                  };
                })(this),
                'Down': (function(_this) {
                  return function(player) {
                    return player.setVolume(player.volume - 0.2);
                  };
                })(this),
                'm': (function(_this) {
                  return function(player) {
                    if (player.muted === true) {
                      return player.unmute();
                    } else {
                      return player.mute();
                    }
                  };
                })(this),
                'Left': (function(_this) {
                  return function(player) {
                    return player.seek(player.currentTime - 10);
                  };
                })(this),
                'Right': (function(_this) {
                  return function(player) {
                    return player.seek(player.currentTime + 10);
                  };
                })(this),
                'j': (function(_this) {
                  return function(player) {
                    return player.seek(player.currentTime - 20);
                  };
                })(this),
                'l': (function(_this) {
                  return function(player) {
                    return player.seek(player.currentTime + 20);
                  };
                })(this),
                '}': (function(_this) {
                  return function(player) {
                    return player.playbackRate += 0.2;
                  };
                })(this),
                '{': (function(_this) {
                  return function(player) {
                    return player.playbackRate -= 0.2;
                  };
                })(this),
                '>': (function(_this) {
                  return function(player) {
                    return player.playbackRate += 0.2;
                  };
                })(this),
                '<': (function(_this) {
                  return function(player) {
                    return player.playbackRate -= 0.2;
                  };
                })(this)
              }
            }
          }
        ],
        theme: '9ae8bbe8dd964ddc9bdb932cca1cb59a',
        autoplay: true,
        container: document.querySelector('#promoVideoVdoPlayerContainer')
      });
      vdo.getObjects()[0].focus()
    } else {
      video = videos[0];
      return video.play();
    }
  }

  _setProductPromoVideoEventListener(productId, productType) {
    var video;
    video = vdo.getObjects()[0];
    video.addEventListener('load', function() {
      $("#loadingIndicatorHolder").hide();
    });
    return video.addEventListener('progress', function() {
      this._trackProductPromoVideoActivity(video, productId, productType);
    }.bind(this));
  }

  _trackProductPromoVideoActivity(video, productId, productType) {
    var completedCutoff, currentTime, halfCutoff, oneFourthCutoff, startCutoff, threeFourthCutoff, totalTime;
    totalTime = Math.round(video.duration);
    currentTime = Math.round(video.currentTime);
    startCutoff = 1;
    oneFourthCutoff = Math.round(totalTime / 4);
    halfCutoff = Math.round(totalTime / 2);
    threeFourthCutoff = Math.round(totalTime * (3 / 4));
    completedCutoff = Math.round(totalTime - 5);
    if (currentTime >= startCutoff && currentTime <= oneFourthCutoff) {
      if (video.productPromoVideoStartedTracked !== true) {
        video.productPromoVideoStartedTracked = true;
        this._postToServer(productId, productType, `${productType}_promo_video_started`)
      }
    } else if (currentTime >= oneFourthCutoff && currentTime <= halfCutoff) {
      if (video.productPromoVideoOneFourthWatchedTracked !== true) {
        video.productPromoVideoOneFourthWatchedTracked = true;
        this._postToServer(productId, productType, `${productType}_promo_video_one_fourth_watched`)
      }
    } else if (currentTime >= halfCutoff && currentTime <= threeFourthCutoff) {
      if (video.productPromoVideoHalfWatchedTracked !== true) {
        video.productPromoVideoHalfWatchedTracked = true;
        this._postToServer(productId, productType, `${productType}_promo_video_half_watched`)
      }
    } else if (currentTime >= threeFourthCutoff && currentTime < completedCutoff) {
      if (video.productPromoVideoThreeFourthWatchedTracked !== true) {
        video.productPromoVideoThreeFourthWatchedTracked = true;
        this._postToServer(productId, productType, `${productType}_promo_video_three_fourth_watched`)
      }
    } else if (currentTime >= completedCutoff) {
      if (video.productPromoVideoCompletedTracked !== true) {
        video.productPromoVideoCompletedTracked = true;
        this._postToServer(productId, productType, `${productType}_promo_video_completed`)
      }
    }
  }

  _postToServer(productId, productType, activityTypeName) {
    $.ajax({
      url: "/api/activities/track-product-promo-video-activities",
      type: 'POST',
      data: {
        product_id: productId,
        product_type: productType,
        activity_type_name: activityTypeName
      }
    });
  }

}
