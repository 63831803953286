import Rails from '@rails/ujs'
import { Controller } from 'stimulus'
import { EVENTS } from 'shared/constants'
import PubSub from 'pubsub-js'
import { unsubscribeTokens } from 'shared/utils/utils'


export default class extends Controller {
  static targets = [ "items", 'item', 'addLink', 'maxMsg' ]

  initialize(){
    this.subscriptions = []
    this._checkMax()
  }

  connect() {
    if (this.data.get('sortable')){
      this._makeSortable()
    }
  }

  disconnect(){
    unsubscribeTokens(this.subscriptions)
  }

  add(e){
    e.preventDefault()
    $.ajax({
      url: this.data.get('url'),
      method: "POST",
      data: this._getParams(),
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data){
        let response = eval(data)
        $(this.itemsTarget).append(response)
        this._checkMax()
      }.bind(this)
    })
  }

  _incrementItemDeleted(){
    if(this.data.has('itemsNotDeletedCount')){
      this.data.set('itemsNotDeletedCount', parseInt(this.data.get('itemsNotDeletedCount')) - 1)
    }
  }

  _decrementItemDeleted(){
    if(this.data.has('itemsNotDeletedCount')){
      this.data.set('itemsNotDeletedCount', parseInt(this.data.get('itemsNotDeletedCount')) + 1)
    }
  }

  _checkMax(){
    if (this.data.has('max') && this.data.has('itemsNotDeletedCount')) {
      if(this.data.get('itemsNotDeletedCount') < this.data.get('max')){
        this.addLinkTarget.classList.remove('hidden')
        this.maxMsgTarget.classList.add('hidden')
      }
      else{
        this.addLinkTarget.classList.add('hidden')
        this.maxMsgTarget.classList.remove('hidden')
      }
    } else {
      this.addLinkTarget.classList.remove('hidden')
      this.maxMsgTarget.classList.add('hidden')
    }
  }

  move(e){
    e.preventDefault()
  }

  delete(e){
    e.preventDefault()
    this._requestToDelete(e.currentTarget.dataset.itemId)
  }

  restore(e){
    e.preventDefault()
    this._requestToRestore(e.currentTarget.dataset.itemId)
  }

  _makeSortable(){
    $(this.itemsTarget).sortable({
      update: function(e, ui){
        this._requestToMoveItem(
          ui.item.data("item-id"),
          $(this.itemsTarget).sortable("toArray",{attribute: "data-sortable-value"})
        )
      }.bind(this)
    })
  }

  _requestToMoveItem(itemId, newItemOrderIds){
    let data = this._getParams()
    data["id"] = itemId
    data["new_items_order"] = newItemOrderIds
    $.ajax({
      url: this.data.get('moveUrl'),
      method: "PATCH",
      data: data,
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      }
    })
  }

  _requestToDelete(item_id){
    $.ajax({
      url: this.data.get('url') + '/' + item_id,
      method: "DELETE",
      data: this._getParams(),
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data){
        this._incrementItemDeleted()
        this._checkMax()
      }.bind(this)
    })
  }

  _requestToRestore(item_id){
    $.ajax({
      url: this.data.get('url') + '/' + item_id + "/restore",
      method: "PUT",
      data: this._getParams(),
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data){
        this._decrementItemDeleted()
        this._checkMax()
      }.bind(this)
    })
  }

  _getParams(){
    let data = {}

    if(this.data.has("params")) {
      let params = JSON.parse(this.data.get("params"))
      for(var key in params){
        data[key] = params[key]
       }
    }

    return data
  }

}
