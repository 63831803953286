import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "enabledContinueButton", "disabledContinueButton" ]

  enableContinueButton() {
    this.enabledContinueButtonTarget.classList.remove('hidden')
    this.disabledContinueButtonTarget.classList.add('hidden')
  }
}
