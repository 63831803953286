import Rails from '@rails/ujs'
import 'bootstrap'
import feather from 'feather-icons'
import 'rateyo'
import Turbolinks from 'turbolinks'
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import 'libs/jquery.fitvids.js'
import { initAllCarousels, removeAllCarousels } from 'shared/utils/utils'
import { EVENTS } from 'shared/constants'
import PubSub from 'pubsub-js'

Rails.start()

//autoloading stimulus controllers
const application = Application.start()

// load controllers for components used in Client Site
const context = require.context('./controllers', true, /\.js$/)
const products_controllers = require.context('components/products/controllers', true,  /\.js$/)
const page_section_controllers = require.context('components/ui/page_sections/controllers', true,  /\.js$/)
const search_controllers = require.context('applications/client_site/components/search/controllers', true, /\.js$/)
const post_controllers = require.context('applications/client_site/components/post/controllers', true, /\.js$/)
const shared_controllers = require.context('shared/controllers', true, /\.js$/)

application.load(definitionsFromContext(context))
application.load(definitionsFromContext(products_controllers))
application.load(definitionsFromContext(page_section_controllers))
application.load(definitionsFromContext(search_controllers))
application.load(definitionsFromContext(post_controllers))
application.load(definitionsFromContext(shared_controllers))

global.$ = jQuery;
Turbolinks.start();

var csrf_token = $('meta[name="csrf-token"]').attr('content')
global.CSRF_TOKEN = csrf_token


document.addEventListener("turbolinks:load", function() {
  $.rails.refreshCSRFTokens();

  //Turbolink persists scroll position of previous page
  //for eg. if user scrolls home page and clicks a course or a collection
  //it will persist that scroll position and open the product sales page at that position
  //not at the top, which is not desired for now
  //we''ll have to see if there is some inbuilt way to override
  //this is a quick fix for now
  document.scrollingElement.scrollTo(0, 0);

  //TODO do something if the image link is broken
  $('img').on('error', function() {
    $(this).attr('src', "https://dummyimage.com/1280x720/a2b5c4/a2b5c4.jpg&text=+");
  });

  initAllCarousels();

  //Load feather icons
  feather.replace();

  $('.video-container').fitVids()

});

//end: turbolinks:load

document.addEventListener('turbolinks:before-cache', function(){
  removeAllCarousels();
})


//loading feather for async loaded content using content-loader controller
PubSub.subscribe(EVENTS.CONTENT.LOADED, function(){
  feather.replace();
});

//show sticky nav after main nav is scrolled

$(window).scroll(function(){
  var winTop = $(window).scrollTop();
  var top = 500;
  if(winTop >= top){
    $("#productsStickyNav").removeClass("invisible fadeOutUp");
    $("#productsStickyNav").addClass("fadeInDown");
  }else{
    $("#productsStickyNav").removeClass("fadeInDown");
    $("#productsStickyNav").addClass("invisible fadeOutUp");
  }
});


$(window).resize(function() {
  feather.replace();
});
