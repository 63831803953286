import { Controller } from 'stimulus'
import { EVENTS } from 'shared/constants'
import PubSub from 'pubsub-js'

export default class extends Controller {

  initialize(){
    this.addFile()
  }

  connect(){}

  disconnect(){}

  addFile(){
    $('#file_upload').fileupload({
      dataType: 'json',
      autoUpload: true,
      add: function (e, form_data) {
        let videoTitle = "Trial Video"
        let videoId = form_data.form[0]["video_id"].value
        let file = form_data.files[0]
        console.log("add video to upload")
        this._create_policy_details_for_new_video(videoTitle, videoId, file, form_data)
      }.bind(this),
      progressall: function (e, data) {
        $('#videoUploadForm').css(
          {"display": "none"}
        )
        let progress = parseInt(data.loaded / data.total * 100, 10);
        if (progress == 100)
        {
          $('#progress').css(
            {"display": "none"}
          )
        } else {
        $('#progress').css(
          {"width": progress + '%', "display": "block", "background-color": "#0D84FF"}
        )
        }
      },
      done: function (e, data) {
        console.log("file upload done")
      }.bind(this)

    })
  }

  _create_policy_details_for_new_video(videoTitle, videoId, file, form_data){
    $.ajax({
      url: "/api/vdo_cipher/videos/new-video-policy-details",
      method: "POST",
      data:{ "shared_video_id": videoId, filename: file.name },
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(policy_data){
        var form = form_data.form[0];
        console.log(form.action)
        form.action = policy_data.uploadLink

        form.elements["x-amz-credential"].value = policy_data["x-amz-credential"]
        form.elements["x-amz-algorithm"].value = policy_data["x-amz-algorithm"]
        form.elements["x-amz-date"].value = policy_data["x-amz-date"]
        form.elements["x-amz-signature"].value = policy_data["x-amz-signature"]

        form.elements["key"].value = policy_data.key
        form.elements["policy"].value = policy_data.policy

        form.elements["success_action_status"].value = 201
        form.elements["success_action_redirect"].value = ''

        form.elements["file"] = file

        form_data.submit();
        let pageSectionId = this.data.get('pageSectionId')
        let videoUploadedUrl = this.data.get('videoUploadedUrl')
        this._video_processing(pageSectionId, videoId, videoUploadedUrl)
      }.bind(this)
    });// end of ajax

  }

  _video_processing(pageSectionId, videoId, videoUploadedUrl){
    $.ajax({
      url: videoUploadedUrl,
      method: "PUT",
      data:{},
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data){
        let evalResponse = eval(data)
        console.log(evalResponse)
        $("#showVideo").html(evalResponse["form"])
        PubSub.publish(EVENTS.THEME.PAGE_SECTION.VIDEO_UPLOADED, {"response": evalResponse, "preview": evalResponse["preview"], "pageSectionId": pageSectionId})
      }.bind(this)
    })
  }

  changeVideo(event){
    console.log("change video")
    console.log(this.data.get('vdoCipherVideoId'))
    let vdoCipherVideoId = this.data.get('vdoCipherVideoId')
    let privateVideoId = this.data.get('privateVideoId')
    let url = this.data.get('pageSectionUpdateUrl')
    let pageSectionId = this.data.get('pageSectionId')
    this._change_video(url, vdoCipherVideoId, pageSectionId, privateVideoId)
  }

  _change_video(url, vdoCipherVideoId, pageSectionId, privateVideoId){
    $.ajax({
      url: url + "/video-with-text/" + vdoCipherVideoId + "/change-video",
      method: "PUT",
      data:{},
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data){
        let evalResponse = eval(data)
        console.log(evalResponse)
        $("#showVideo").html(evalResponse["form"])
        PubSub.publish(EVENTS.THEME.PAGE_SECTION.VIDEO_CHANGED, {"response": evalResponse, "preview": evalResponse["preview"], "pageSectionId": pageSectionId})
      }.bind(this)
    })
  }

  playVideo(event){
    console.log("play video")
    console.log(this.data.get('vdoCipherVideoId'))
    let vdoCipherVideoId = this.data.get('vdoCipherVideoId')
    this._get_playback_info(vdoCipherVideoId)
  }

  _get_playback_info(vdoCipherVideoId){
    $.ajax({
      url: "/api/vdo_cipher/" + vdoCipherVideoId + "/playback-info",
      method: "GET",
      data:{},
      headers:{
        'X-CSRF-Token': CSRF_TOKEN
      },
      success: function(data){
        var vdoCipherPlayer = new VdoPlayer({
          otp: data.otp,
          playbackInfo: btoa(JSON.stringify({
            videoId: vdoCipherVideoId
          })),
          theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
          container: document.querySelector( "#embedBox" )
        })

        vdoCipherPlayer.addEventListener('load', function() {
          vdoCipherPlayer.play()
        })
      }.bind(this)
    })
  }

  startVideo(vdoCipherPlayer){
    console.log("start video")
    vdoCipherPlayer.play()
  }

}
