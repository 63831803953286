import Rails from '@rails/ujs'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    // Hidden Fields
    "billingInfoFullNameHiddenField",
    "billingInfoEmailHiddenField",
    "billingInfoMobileNumberHiddenField",
    "couponCodeTextHiddenField",

    // Billing Information
    "billingInfoFullNameTextbox",
    "billingInfoFullNameTextboxAlert",
    "billingInfoMobileNumberTextbox",
    "billingInfoMobileNumberTextboxAlert",
    "billingInfoEmailTextbox",
    "billingInfoEmailTextboxAlert",

    // Action Button Panel
    "actionButtonPanelProceedToPayButton",
    "actionButtonPanelProceedToPayButtonContainer",
    "actionButtonPanelRedeemPrepaidCodeSection",

    // Coupon Codes
    "couponCodePanelApplyCouponLinkContainer",
    "couponCodePanelApplyCouponLink",
    "couponCodePanelRemoveCouponLink",
    "couponCodePanelApplyCouponForm",
    "couponCodePanelAppliedMessageSection",
    "couponCodePanelAppliedMessageHeading",
    "couponCodePanelAppliedMessageText",
    "couponCodePanelApplyCouponButton",
    "couponCodePanelCouponCodeTextbox",
    "couponCodePanelErrorMessageSection",
    "couponCodePanelErrorMessageText",

    // Referral Discount
    "referralDiscountCouponCodeBlockedBannerContainer",
    "referralDiscountBannerContainer",
    "referralDiscountLineItemContainer",

    // Before Purchase Upsells
    "beforePurchaseProductVariantUpsellsSelectionStatuses",
    "beforePurchaseProductVariantUpsellsItemsInOrderList",

    // Order Summary
    "orderSummaryOrderTotalPrice",
    "orderSummaryOrderSubTotalPrice",
    "orderSummaryBeforePurchaseUpsellPrices",
    "orderSummaryDiscountPrice",
    "orderSummaryDiscountPriceContainer"
  ]

  initialize(){}

  connect(){
    this.enableOrDisableProceedToPayButton();

    //-- Billing Info
    // Start showing alert once the user leaves the field.
    // Otherwise, user sees 'Email is invalid' on starting to type email.
    this.billingInfoShouldShowFullNameAlert = false;
    this.billingInfoShouldShowMobileNumberAlert = false;
    this.billingInfoShouldShowEmailAlert = false;

    this._preApplyCoupon(this.data.get('preAppliedCouponCodeText'));
    this.updatePrice();
  }

  disconnect(){}

  //#region Billing Info
  billingInfoFullNameOnBlur(){
    if (this.billingInfoShouldShowFullNameAlert == true){
      return
    }
    else {
      this.billingInfoShouldShowFullNameAlert = true;
      this.billingInfoShowFullNameAlertIfRequired();
    }
  }

  billingInfoFullNameValueChanged(){
    this.billingInfoFullNameHiddenFieldTargets.forEach((target, index) => {
      target.value = this.billingInfoFullNameTextboxTarget.value;
    })

    this.enableOrDisableProceedToPayButton();
    this.billingInfoShowFullNameAlertIfRequired();

  } // billingInfoFullNameValueChanged

  billingInfoShowFullNameAlertIfRequired(){
    if (this.billingInfoFullNameTextboxTarget.value === '' || this.billingInfoFullNameTextboxTarget.value == null) {
      this.billingInfoShowFullNameAlert("Name is required")
    } else {
      this.billingInfoHideFullNameAlert()
    }
  }

  billingInfoShowFullNameAlert(message){
    if (this.billingInfoShouldShowFullNameAlert != true){
      return;
    }
    else {
      this.billingInfoFullNameTextboxAlertTarget.classList.remove('hidden');
    }
  }

  billingInfoHideFullNameAlert() {
    this.billingInfoFullNameTextboxAlertTarget.classList.add('hidden');
  }

  billingInfoMobileNumberOnBlur(){
    if (this.billingInfoShouldShowMobileNumberAlert == true){
      return
    }
    else {
      this.billingInfoShouldShowMobileNumberAlert = true;
      this.billingInfoShowMobileNumberAlertIfRequired();
    }
  }

  billingInfoMobileNumberValueChanged() {
    this.billingInfoMobileNumberHiddenFieldTargets.forEach((target, index) => {
      target.value = `${this.data.get('countryCallingCode')}-${this.billingInfoMobileNumberTextboxTarget.value}`;
    })

    this.enableOrDisableProceedToPayButton();
    this.billingInfoShowMobileNumberAlertIfRequired();
  }

  billingInfoShowMobileNumberAlertIfRequired(){
    if (this.hasBillingInfoMobileNumberTextboxTarget) {
      if (this.billingInfoMobileNumberTextboxTarget.value === '' || this.billingInfoMobileNumberTextboxTarget.value == null) {
        this.billingInfoShowMobileNumberAlert('Mobile number is required');
      } else if (this.isMobileNumberValid(this.billingInfoMobileNumberTextboxTarget.value) != true){
        this.billingInfoShowMobileNumberAlert('Mobile number is invalid');
      }
      else {
        this.billingInfoHideMobileNumberAlert();
      }
    }
  }

  billingInfoShowMobileNumberAlert(message){
    if (this.billingInfoShouldShowMobileNumberAlert != true){
      return;
    }
    else {
      this.billingInfoMobileNumberTextboxAlertTarget.classList.remove('hidden');
      this.billingInfoMobileNumberTextboxAlertTarget.innerHTML = message;
    }
  }

  billingInfoHideMobileNumberAlert(){
    this.billingInfoMobileNumberTextboxAlertTarget.classList.add('hidden');
  }

  isMobileNumberValid(mobileNumber){
    // It will return true if mobileNumber is valid else false
    return /^\d{10}$/i.test(mobileNumber)
  }

  billingInfoEmailOnBlur(){
    if (this.billingInfoShouldShowEmailAlert == true){
      return
    }
    else {
      this.billingInfoShouldShowEmailAlert = true;
      this.billingInfoShowEmailAlertIfRequired();
    }
  }

  billingInfoEmailValueChanged(){
    this.billingInfoEmailHiddenFieldTargets.forEach((target, index) => {
      target.value = this.billingInfoEmailTextboxTarget.value
    })

    this.enableOrDisableProceedToPayButton();
    this.billingInfoShowEmailAlertIfRequired();
  } // billingInfoEmailValueChanged

  billingInfoShowEmailAlertIfRequired(){
    if (this.billingInfoEmailTextboxTarget.value === '' || this.billingInfoEmailTextboxTarget.value == null) {
      this.billingInfoShowEmailAlert('Email is required');
    } else if (this.isEmailAddressValid(this.billingInfoEmailTextboxTarget.value) != true){
      this.billingInfoShowEmailAlert('Email is invalid');
    }
    else {
      this.billingInfoHideEmailAlert();
    }
  }

  billingInfoShowEmailAlert(message){
    if (this.billingInfoShouldShowEmailAlert != true){
      return;
    }
    else {
      this.billingInfoEmailTextboxAlertTarget.classList.remove('hidden');
      this.billingInfoEmailTextboxAlertTarget.innerHTML = message;
    }
  }

  billingInfoHideEmailAlert(){
    this.billingInfoEmailTextboxAlertTarget.classList.add('hidden');
  }

  isEmailAddressValid(email){
    // It will return true if email is valid else false
    return /^[\w+\-.]+@[a-z\d\-]+(\.[a-z\d\-]+)*\.[a-z]+$/i.test(email);
  }

  isBillingInformationValid(){
    if (
      this.billingInfoFullNameTextboxTarget.value === '' || this.billingInfoFullNameTextboxTarget.value == null ||
      this.isEmailAddressValid(this.billingInfoEmailTextboxTarget.value) != true ||
      (this.hasBillingInfoMobileNumberTextboxTarget && this.isMobileNumberValid(this.billingInfoMobileNumberTextboxTarget.value) != true)){
      return false;
    }
    else {
      return true;
    }
  }
  //#endregion Billing Information

  //#region Proceed To Pay Button
  enableOrDisableProceedToPayButton(){
    if (this.data.get("isAppUserLoggedIn") === 'true'){
      this.enableProceedToPayButton();
    }
    else if (this.isBillingInformationValid()){
      this.enableProceedToPayButton();
    }
    else {
      this.disableProceedToPayButton();
    }
  }

  enableProceedToPayButton(){
    this.actionButtonPanelProceedToPayButtonTargets.forEach((target, index) => {
      target.removeAttribute("disabled");
    })
  }

  disableProceedToPayButton(){
    this.actionButtonPanelProceedToPayButtonTargets.forEach((target, index) => {
      target.setAttribute("disabled", "disabled");
    })
  }
  //#endregion Proceed To Pay Button

  //#region CouponCode
  showCouponForm(event){
    // event will be undefined while pre appling coupon
    if(event != undefined){
      event.preventDefault();
    }

    this.couponCodePanelApplyCouponLinkContainerTarget.classList.add("hidden");
    this.couponCodePanelApplyCouponFormTarget.classList.remove("hidden");
  }

  hideCouponForm(event){
    event.preventDefault();
    this.couponCodePanelApplyCouponLinkContainerTarget.classList.remove("hidden");
    this.couponCodePanelApplyCouponFormTarget.classList.add("hidden");

    // Hide discount
    this.orderSummaryDiscountPriceContainerTarget.classList.add("hidden")
  }

  removeCoupon(event){
    event.preventDefault();
    this.orderSummaryDiscountPriceContainerTarget.classList.add("hidden")
    this.couponCodePanelApplyCouponLinkContainerTarget.classList.remove("hidden");
    this.couponCodePanelAppliedMessageSectionTarget.classList.add("hidden");
    this.actionButtonPanelProceedToPayButtonContainerTarget.classList.remove("hidden");
    this.actionButtonPanelRedeemPrepaidCodeSectionTarget.classList.add("hidden");
    this.enableAndClearForm();
    this.updatePrice();
  }

  applyCoupon(event){
    // event will be undefined while pre appling coupon
    if(event != undefined){
      event.preventDefault();
    }

    this.disableFormShowApplyingMessage();

    this.updatePrice()
  }

  disableFormShowApplyingMessage(){
    this.hideErrorMessage()
    this.couponCodePanelCouponCodeTextboxTarget.disabled = true;
    this.couponCodePanelApplyCouponButtonTarget.disabled = true;
    this.couponCodePanelApplyCouponButtonTarget.innerHTML = "Applying...";
  }

  enableForm(){
    this.couponCodePanelCouponCodeTextboxTarget.disabled = false;
    this.couponCodePanelApplyCouponButtonTarget.disabled = false;
    this.couponCodePanelApplyCouponButtonTarget.innerHTML = "Apply coupon";
  }

  enableAndClearForm(){
    this.enableForm()
    this.couponCodePanelCouponCodeTextboxTarget.value = "";
    this.couponCodeTextHiddenFieldTargets.forEach((target, index) => {
      target.value = "";
    })
  }

  appliedSuccessfully(data){
    //Set the values in the card.
    this.couponCodePanelAppliedMessageHeadingTarget.innerHTML = data.successMessageLine1;
    this.couponCodePanelAppliedMessageTextTarget.innerHTML = data.successMessageLine2;

    // Show discount
    this.orderSummaryDiscountPriceContainerTarget.classList.remove("hidden")
    this.orderSummaryDiscountPriceTarget.innerHTML = data.successDiscountAmountDisplayValue

    //hide apply coupon code and show coupon applied message
    this.couponCodePanelApplyCouponLinkContainerTarget.classList.add("hidden");
    this.couponCodePanelAppliedMessageSectionTarget.classList.remove("hidden");
    this.couponCodePanelApplyCouponFormTarget.classList.add("hidden");

    // Set coupon code value in hidden field
    this.couponCodeTextHiddenFieldTargets.forEach((target, index) => {
      target.value = this.couponCodePanelCouponCodeTextboxTarget.value;
    })
  }

  redeemVoucherCode(data){
    this.disableFormShowApplyingMessage();

    //Show redeem button instead of proceed to pay.
    this.actionButtonPanelProceedToPayButtonContainerTarget.classList.add("hidden");
    this.actionButtonPanelRedeemPrepaidCodeSectionTarget.classList.remove("hidden");

    //Set the values in the card.
    this.couponCodePanelAppliedMessageHeadingTarget.innerHTML = data.successMessageLine1;
    this.couponCodePanelAppliedMessageTextTarget.innerHTML = data.successMessageLine2;

    //hide apply coupon code and show coupon applied message
    this.couponCodePanelApplyCouponLinkContainerTarget.classList.add("hidden");
    this.couponCodePanelAppliedMessageSectionTarget.classList.remove("hidden");
    this.couponCodePanelApplyCouponFormTarget.classList.add("hidden");

    // Set coupon code value in hidden field
    this.couponCodeTextHiddenFieldTargets.forEach((target, index) => {
      target.value = this.couponCodePanelCouponCodeTextboxTarget.value;
    })
  }

  failedToApply(errorMessage){
    this.enableForm();
    this.showErrorMessage(errorMessage);
  }

  showErrorMessage(msg){
    if(msg != null){
      this.couponCodePanelErrorMessageSectionTarget.classList.remove("hidden");
      this.couponCodePanelErrorMessageTextTarget.innerHTML = "<span>"+msg+"</span>";
    }
    else{
      this.couponCodePanelErrorMessageSectionTarget.classList.add("hidden");
      this.couponCodePanelErrorMessageTextTarget.innerHTML = "";
    }
  }

  hideErrorMessage(){
    // event.preventDefault();
    this.couponCodePanelErrorMessageTextTarget.innerHTML = "";
    this.couponCodePanelErrorMessageSectionTarget.classList.add("hidden");
  }

  _preApplyCoupon(preAppliedCouponCodeText){
    if(preAppliedCouponCodeText == "___NO_COUPON_CODE_TO_PREFILL___" || preAppliedCouponCodeText == "" || preAppliedCouponCodeText == undefined || preAppliedCouponCodeText == null){
      return
    }

    this.showCouponForm();
    this.couponCodePanelCouponCodeTextboxTarget.value = preAppliedCouponCodeText;
    this.disableFormShowApplyingMessage();
  }

  handleCouponCodeResponse(result) {
    var couponCodeData = result['coupon_code_data']

    if(couponCodeData.applyStatus == 'not_entered') {

    } else {
      var status = couponCodeData.applyStatus;
      if (result['order_total'] == 0) {
        if (status === 'success') {
          this.redeemVoucherCode(couponCodeData);
        } else if (status === 'failed') {
          this.failedToApply(data.failedMessage);
        }
      } else {
        if (status === 'success') {
          this.appliedSuccessfully(couponCodeData);
        } else if (status === 'failed') {
          this.failedToApply(couponCodeData.failedMessage);
        }
      }
    }

  } // handleCouponCodeResponse

  handleReferralDiscountResponse(result) {
    if (result['referral_discount_data'].block_coupon_code == 'yes') {
      this.couponCodePanelApplyCouponLinkTarget.classList.add("hidden")
    } else {
      this.couponCodePanelApplyCouponLinkTarget.classList.remove("hidden")
    }

    this.referralDiscountCouponCodeBlockedBannerContainerTarget.innerHTML = result['referral_discount_coupon_code_blocked_banner_html'];
    this.referralDiscountBannerContainerTarget.innerHTML = result['referral_discount_banner_html'];
    this.referralDiscountLineItemContainerTarget.innerHTML = result['referral_discount_line_item_html'];
  } // handleReferralDiscountResponse
  //#endregion CouponCode

  //#region BeforePurchase Upsell
  beforePurchaseUpsellAddProductButtonClicked(event) {
    var clickedProductVariantUpsellId = parseInt(event.target.dataset.productVariantUpsellId)
    this._setStatusForProductVariantUpsell(clickedProductVariantUpsellId, 'selected')
    this.updatePrice(clickedProductVariantUpsellId, null)
  }

  beforePurchaseUpsellRemoveProductButtonClicked(event){
    var removedProductVariantUpsellId = parseInt(event.currentTarget.dataset.productVariantUpsellId)
    this._setStatusForProductVariantUpsell(removedProductVariantUpsellId, 'not_selected')
    this.updatePrice(null, removedProductVariantUpsellId);
  }

  _setStatusForProductVariantUpsell(upsellId, status) {
    this.beforePurchaseProductVariantUpsellsSelectionStatusesTargets.forEach((target, index) => {
      var currentProductVariantUpsellId = parseInt(target.dataset.productVariantUpsellId)
      if (currentProductVariantUpsellId == upsellId) {
        target.value = status
      }
    })
  }

  _selectedBeforePurchaseProductVariantUpsellIds() {
    var ids = [];

    this.beforePurchaseProductVariantUpsellsSelectionStatusesTargets.forEach((target, index) => {
      if(target.value == 'selected') {
        ids.push(parseInt(target.dataset.productVariantUpsellId))
      }
    })

    return ids;
  }

  beforePurchaseUpsellMarkProductAsAdded(upsellAddedId) {
    if (upsellAddedId != null) {
      $(`#before_purchase_upsell_add_button_${upsellAddedId}`).addClass("hidden")
      $(`#before_purchase_upsell_added_button_${upsellAddedId}`).removeClass("hidden")
    }
  }

  beforePurchaseUpsellMarkProductAsRemoved(upsellRemovedId) {
    if (upsellRemovedId != null) {
      $(`#before_purchase_upsell_add_button_${upsellRemovedId}`).removeClass("hidden")
      $(`#before_purchase_upsell_added_button_${upsellRemovedId}`).addClass("hidden")
    }
  }
  //#endregion BeforePurchase Upsell

  //#region Pricing Update
  updatePrice(upsellAddedId, upsellRemovedId){
    var selectedBeforePurchaseProductVariantUpsellIds = this._selectedBeforePurchaseProductVariantUpsellIds()

    var data = {
      target_type: this.data.get("productType"),
      target_id: this.data.get("productId"),
      product_variant_price_id: this.data.get("productVariantPriceId"),
      coupon_code_text: this.couponCodePanelCouponCodeTextboxTarget.value,
      before_purchase_product_variant_upsell_ids: selectedBeforePurchaseProductVariantUpsellIds
    }

    $.ajax({
      type: "GET",
      url: `/api/payments/checkout/calculate-final-price.js`,
      data: data,
      success: function(data) {
        var result = eval(data);

        // Update OrderSummary prices
        this.orderSummaryOrderTotalPriceTarget.innerHTML = result['order_total_display_value']
        this.orderSummaryOrderSubTotalPriceTarget.innerHTML = result['sub_total_display_value']
        this.orderSummaryBeforePurchaseUpsellPricesTarget.innerHTML = result['upsell_order_summary_html'];

        // Update items in order
        this.beforePurchaseProductVariantUpsellsItemsInOrderListTarget.innerHTML = result['upsell_items_in_order_html'];

        // Update BeforePurchase Upsells product buttons
        this.beforePurchaseUpsellMarkProductAsAdded(upsellAddedId)
        this.beforePurchaseUpsellMarkProductAsRemoved(upsellRemovedId)

        // Handle coupon code response
        this.handleCouponCodeResponse(result)

        // Handle referral discount response
        this.handleReferralDiscountResponse(result)
      }.bind(this),
      error: function(error) {
        this.failedToApply("Failed to apply coupon code.");
      }.bind(this)
    });
  }
  //#endregion Pricing Update
}
