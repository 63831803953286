import { Controller } from 'stimulus'
import consumer from 'channels/consumer'
import 'libs/jquery.fitvids.js'
import { initAllCarousels } from 'shared/utils/utils'

export default class extends Controller {
  static targets = ["content"]

  connect() {
    this.uiPageId = this.data.get('uiPageId');
    if (this.uiPageId !== null){
      this.channel = consumer.subscriptions.create(
        { channel: "ClientSitePreviewChannel", app_uid: this.data.get('appUid'), ui_page_id: this.uiPageId },
        { received: this._recieveFromChannel.bind(this) }
      );
    }

    window.onmessage = function(e){
      this._receiveFromWindow(e.data);
    }.bind(this)
  }

  disconnect() {
    if (this.channel !== null){
      this.channel.unsubscribe()
    }
  }

  _currentResourceDomId(data){
    return '#' + data.resourceDomId
  }

  _receiveFromWindow(data){
    if(typeof data !== 'undefined' && typeof this[data.action] !== 'undefined' && data != null){
      this[data.action](data)
    }
  }

  _updatePrimaryAndSecondaryColor(data){
    $(`.t-btn-primary_link`).css({ color: data.newColor.primaryColor });
    $(`.t-btn-primary`).css({ 'background-color': data.newColor.primaryColor, 'border-color': data.newColor.primaryColor});
  }

  _recieveFromChannel(data) {
    if(typeof data !== 'undefined' && typeof this[data.action] !== 'undefined' && data != null){
      this[data.action](data)
    }
  }

  _create(data){
    let resourceId =  this._currentResourceDomId(data)
    $(this.contentTarget).append(eval(data.preview))
    this._scrollToResource(resourceId)
    $(this.contentTarget).find(".video-container").fitVids()
    initAllCarousels(resourceId)
  }

  _edit(data){
    let resourceId =  this._currentResourceDomId(data)
    this._scrollToResource(resourceId)
  }

  _update(data){
    let resourceId =  this._currentResourceDomId(data)
    $(resourceId).replaceWith(eval(data.preview))
    this._scrollToResource(resourceId)
    $(resourceId).find(".video-container").fitVids()
    initAllCarousels(resourceId)
  }

  _saveStyle(data){
    this._update(data)
  }

  _destroy(data){
    let resourceId =  this._currentResourceDomId(data)

    $('html, body').animate({
      scrollTop: $(resourceId).offset().top - 100
    }, 150, function(){
      setTimeout(
        function(){
          $(resourceId).addClass("-translate-x-full opacity-0");
        },
        150,
        setTimeout(
          function(){
            $(resourceId).hide()
          },
          500
        )
      )
    });
  }

  _restore(data){
    let resourceId =  this._currentResourceDomId(data)
    $(resourceId).show()
    $('html, body').animate({
      scrollTop: $(resourceId).offset().top - 100
    }, 150, function(){
      setTimeout(
        function(){
          $(resourceId).removeClass("opacity-0 -translate-x-full");
        },
        300
      )
    });
  }

  _move(data){
    window.parent.postMessage("beginMovingPageSection", "*");
    let $moveSection = $(this._currentResourceDomId(data))
    $moveSection.addClass("opacity-0");
    for (var position=0; position<=data.newPageSectionOrder.length-1; position++){
      if(data.resourceId == data.newPageSectionOrder[position]){
        if(position==data.newPageSectionOrder.length-1){
          $moveSection.appendTo(this.contentTarget)
        }
        else{
          $moveSection.insertBefore($("#page-section-preview" + "-" + data.newPageSectionOrder[position+1]))
        }
        $('html, body').animate({
          scrollTop: $moveSection.offset().top-300
        }, 1000);
      }
    }
    window.setTimeout(function(){
      window.parent.postMessage("endMovingPageSection", "*");
      $moveSection.removeClass("opacity-0");
    }.bind(this), 1000)
  }

  _published(data){
    window.location.reload(true);
    document.scrollingElement.scrollTo(0, 0);
  }

  _scrollToResource(resourceId){
    $('html, body').animate({
      scrollTop: $(resourceId).offset().top - 100
    }, 1000);
  }

  _blockUpdate(data){
    let resourceId = this._currentResourceDomId(data)
    $(resourceId).replaceWith(eval(data.blockPreview))
    $(resourceId).find(".video-container").fitVids()
    initAllCarousels(resourceId)
  }

  _blockCreate(data){
    this._blockUpdate(data)
  }

  _blockMove(data){
    this._blockUpdate(data)
  }

  _blockDestroy(data){
    this._blockUpdate(data)
  }

  _blockRestore(data){
    this._blockUpdate(data)
  }

  _blockSaveStyle(data){
    this._blockUpdate(data)
  }
}
